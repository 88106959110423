<template>
	<div style="position: relative;">
		<PointDataBgc ref="PointDataBgc" v-if="renderComponent">
		</PointDataBgc>
		<div class="posiFlex right20">
			<div class="aboust_bott20">
				<p class="explain_right" @click="shangyyiye()">上一页 </p>
				<p class="explain_right" @click="xiayiye()">下一页 </p>
			</div>
		</div>
		<div class="posiFlex left20">
			<div class="aboust_bott20">
				<p class="explain_right" @click="shangyyiye()">上一页 </p>
				<p class="explain_right" @click="xiayiye()">下一页 </p>
			</div>
		</div>
	</div>
</template>

<script>
	import PointDataBgc from '../../components/ComponentPage/PointDataBgc.vue'
	import {
		queryDetailById
	} from '../../api/Collect.js'
	import {
		queryPlanById
	} from '../../api/base.js'
	import {
		queryMacSingList
	} from '../../api/Correct.js'
	import {queryMacList} from '../../api/base.js'
	import teachlanguagechian from '../../assets/json/chian.json'
	import teachlanguageenglish from '../../assets/json/english.json'
	export default {
		name: 'Person',
		components: {
			PointDataBgc
		},
		computed: {
			
		},
		watch: {
			
		},
		data() {
			return {
				classItem:{
					penMac:''
				},
				planId:localStorage.getItem('teachMicroClass')?JSON.parse(localStorage.getItem('teachMicroClass')).id:null,
				userId:localStorage.getItem('teachlogin')?JSON.parse(localStorage.getItem('teachlogin')).id:null,
				beginTime:'',
				endTime:'',
				userToken:'',
				renderComponent:true,
				canvesWight: 1033.4,
				canvesHeight: 1461.6,
				img_list_index:0,
				pageIds:[],
				queryDetailByIdData:[],
				classData:{},
				img_list:["https://api.youcun.tech/epen-slice-upload/show/file/uploads/data/823dbd4c2756d7c0165ed28ce7272559/220707111922575/0354de965f84448bbff100962fd95af2.png","https://api.youcun.tech/epen-slice-upload/show/file/uploads/data/823dbd4c2756d7c0165ed28ce7272559/220707111922575/27e633db1cac42d38f9355795bf52dfc.png","https://api.youcun.tech/epen-slice-upload/show/file/uploads/data/823dbd4c2756d7c0165ed28ce7272559/220707111922575/f3f65f00bb56459e997052b650d0e54d.png"]
			}
		},
		created() {
			
		},
		mounted() {
			this.$store.state.teachlanguagedata=teachlanguagechian
			// var search = 'http://172.16.0.101:8080/#/HandwritingsRem?setId=2364368931242054&userId=2015'
			var search = window.location.href;
			var data = this.queryURLParams(search);
			
			this.planId=data.planId?data.planId:this.planId
			this.userId=data.userId?data.userId:this.userId
			this.beginTime=data.beginTime?data.beginTime:this.beginTime
			this.endTime=data.endTime?data.endTime:this.endTime
			localStorage.setItem('teachNote',JSON.stringify({beginTime:this.beginTime,endTime:this.endTime}))
			this.userToken=data.userToken?data.userToken:this.userToken
			this.userToken = decodeURIComponent(decodeURI(this.userToken)).replace(/ /g, "+")
			localStorage.setItem("teachuserToken", JSON.stringify(this.userToken))
			this.QueryPlanById()
		},
		beforeDestroy() {
			
		},
		destroyed() {
			
		},
		methods: {
			QueryPlanById(){
				queryPlanById({id:this.planId}).then(res=>{
					this.setId=this.$Download.getQuestionSet(res.data.result.resourceRelationList,'questionSet')
					localStorage.setItem('teachQuestionSetInformation',JSON.stringify(res.data.result))
					this.QueryDetailById()
				})
			},
			xiayiye() {
				if(this.img_list_index<this.img_list.length-1){
					this.img_list_index+=1
					this.renderComponentFun('1')
				}else{
					this.$message("没有下一页")
				}
			},
			shangyyiye() {
				if(this.img_list_index>0){
					this.img_list_index-=1
					this.renderComponentFun('1')
				}else{
					this.$message("没有上一页")
				}
			},
			renderComponentFun(data){
				this.renderComponent = false;
				this.$nextTick(() => {
				  this.renderComponent = true;
				  this.$nextTick(()=>{
					  if(data=='1'){
						  this.$refs.PointDataBgc.initialization()
					  }
				  })
				});
			},
			//当前题集信息
			QueryDetailById() {
				let data = {
					setId: this.setId,
					// setId: 2364368931241844,
				}
				let formData = new FormData();
				for (var key in data) {
					formData.append(key, data[key]);
				}
				queryDetailById(formData).then(res => {
					this.queryDetailByIdData=res.data.result
					if(this.queryDetailByIdData.epenCodeList){
						this.img_list=this.AttributeVariableArray(this.queryDetailByIdData.epenCodeList,'inputUrl')
						this.pageIds=this.AttributeVariableArray(this.queryDetailByIdData.epenCodeList,'pageId')
						this.classData.Image=this.img_list[this.img_list_index]
					}else{
						this.classData.Image=null
						this.pageIds=[0]
					}
					this.QueryMacList()
				})
			},
			QueryMacList(){
				let canshu=JSON.parse(localStorage.getItem('teachQuestionSetInformation'))
				let chuan={
					"userId": this.userId
				}
				if(canshu.roomId){
					chuan.beginTime=canshu.beginTime
					chuan.endTime=canshu.endTime
				}
				queryMacSingList(chuan).then(res=>{
					this.classItem.penMac=res.data.result[0].penMac
					this.$refs.PointDataBgc.initialization()
				})
			},
			// arry 对象 Attribute 属性
			AttributeVariableArray(arry,Attribute){
				let images = []
				arry.forEach(item => {
					if (images.indexOf(item[Attribute]) == '-1') {
						images.push(item[Attribute])
					}
				})
				return images
			},
			queryURLParams(url) {
				let result = {}
				let reg1 = /([^?=&#]+)=([^?=&#]+)/g
				let reg2 = /#([^?=&#]+)/g;
				url.replace(reg1, (n, x, y) => result[x] = y);
				url.replace(reg2, (n, x) => result['HASH'] = x);
				return result
			},
		}
	}
</script>

<style scoped="scoped">
	.aboust_bott20 {
		white-space: nowrap;
	}

	.aboust_top20 {
		white-space: nowrap;
	}

	.container_con1 {
		font-size: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.posiFlex{
		position: fixed;
		bottom: 15%;
	}
	.right20{
		right: 20px;
	}
	.left20{
		left: 20px;
	}
</style>
